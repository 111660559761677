import React, { useRef, useState } from 'react';
import { Scrollama, Step } from 'react-scrollama';
import { Layout } from 'antd';
const { Footer } = Layout;

import { ApplicationData } from '@bwb-buildings-mono/entities';

import './main-app.scss';

/* eslint-disable-next-line */

const scrollToRefSmooth = (ref) => ref.current.scrollIntoView({ behavior: 'smooth' });

export const MainApp = (props: ApplicationData) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(null);

  let sectionRefs = [];
  props.sections.forEach((section, i) => {
    sectionRefs[i] = useRef(null);
  });

  const onNavClick = (label:string) => {
    const section = props.sections.find((section, i) => {
      return section.label == label;
    })
    if(section.props.sectionRef){
      scrollToRefSmooth(section.props.sectionRef);
    }
  }

  return (
    <Layout className="layout" style={{maxWidth: '100%', margin: '0 auto', backgroundColor: 'white'}}>
      <Scrollama onStepEnter={({data}) => setCurrentStepIndex(data)}>
        {
          props.sections.map((section, i) => {
            let sectionprops = section.props || {};
            sectionprops.sectionRef = sectionRefs[i];
            if(section.isNav){
              sectionprops.onClick = onNavClick;
              sectionprops.currentStepIndex = currentStepIndex;
              sectionprops.sections = props.sections;
            }
            
            return (
              <Step data={i} key={'section-' + i}>
                <div>
                  { section.custom ? section.element : <section.element {...sectionprops}/> }
                </div>
              </Step>
            )
          })
        }
      </Scrollama>
      <Footer style={{ textAlign: 'center' }}>{ props.footer }</Footer>
    </Layout>
  );
};

export default MainApp;
