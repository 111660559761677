import React from 'react';
import { Row, Col, Typography, Divider } from 'antd';
const { Paragraph, Text } = Typography;
import { ApplicationData } from '@bwb-buildings-mono/entities';
import * as UI from '@bwb-buildings-mono/ui';

const PHONE_NUMBER = '(818)995-8400';
const EMAIL = 'brenda@metroart.com';
const ADDRESS_NAME = '4243 Mary Ellen';
const ADDRESS_1 = '4243 Mary Ellen Ave';
const ADDRESS_2 = 'Studio City, CA 91604';

const amentities = {
    sentence: <div style={{fontSize: '18px', lineHeight: '18px', textAlign: 'center'}}>
      <Paragraph>Quiet, private street</Paragraph>
      <Paragraph>Beautiful lush gardens and courtyard</Paragraph>
      <Paragraph>Secure parking garage below the units</Paragraph>
      <Paragraph>Controlled access building with intercom</Paragraph>
      <Paragraph>One block from lively Ventura Blvd - walking distance to markets, restaurants, and more!</Paragraph>
    </div>,
    images: [
      {
        src: 'assets/web/common---1.jpg',
        caption: '',
        thumb: 'assets/web/common---1.jpg'
      },
      {
        src: 'assets/web/common---2.jpg',
        caption: '',
        thumb: 'assets/web/common---2.jpg'
      }
    ]
}

const features = {
    sentence: <div style={{fontSize: '18px', lineHeight: '18px', textAlign: 'center'}}>
        <Paragraph>Elegant Mediterranean architecture with stunning wood details</Paragraph>
        <Paragraph>Private townhouse style with a personal roof deck</Paragraph>
        <Paragraph>Waterproof wood-style floors and tall ceilings</Paragraph>
        <Paragraph>Central heat and air conditioning</Paragraph>
        <Paragraph>Open floor plan and bright units</Paragraph>
        <Paragraph>Full size Washer and Dryer in the unit</Paragraph>
        <Paragraph>2 full bathrooms upstairs and a half bathroom downstairs</Paragraph>
        <Paragraph>New upgraded stainless steel appliances in the kitchen</Paragraph>
        <Paragraph>New carpet in the bedrooms</Paragraph>
    </div>,
  images: [
    {
        src: 'assets/web/apartments-01.jpg',
        caption: '',
        thumb: 'assets/web/apartments-01.jpg'
    },
    {
        src: 'assets/web/apartments-02.jpg',
        caption: '',
        thumb: 'assets/web/apartments-02.jpg'
    },
    {
        src: 'assets/web/apartments-03.jpg',
        caption: '',
        thumb: 'assets/web/apartments-03.jpg'
    },
    {
        src: 'assets/web/apartments-04.jpg',
        caption: '',
        thumb: 'assets/web/apartments-04.jpg'
    },
    {
        src: 'assets/web/apartments-05.jpg',
        caption: '',
        thumb: 'assets/web/apartments-05.jpg'
    },
    {
        src: 'assets/web/apartments-06.jpg',
        caption: '',
        thumb: 'assets/web/apartments-06.jpg'
    },
    {
        src: 'assets/web/apartments-07.jpg',
        caption: '',
        thumb: 'assets/web/apartments-07.jpg'
    },
    {
        src: 'assets/web/apartments-08.jpg',
        caption: '',
        thumb: 'assets/web/apartments-08.jpg'
    },
    {
        src: 'assets/web/apartments-08b.jpg',
        caption: '',
        thumb: 'assets/web/apartments-08b.jpg'
    },
    {
        src: 'assets/web/apartments-09.jpg',
        caption: '',
        thumb: 'assets/web/apartments-09.jpg'
    },
    {
        src: 'assets/web/apartments-10.jpg',
        caption: '',
        thumb: 'assets/web/apartments-10.jpg'
    },
    {
        src: 'assets/web/apartments-11.jpg',
        caption: '',
        thumb: 'assets/web/apartments-11.jpg'
    },
    {
        src: 'assets/web/apartments-12.jpg',
        caption: '',
        thumb: 'assets/web/apartments-12.jpg'
    }
  ]
}

export const AppData:ApplicationData = {
    footer: <div>
        <div>
            <Row>
                <Col md={24} xs={0}>
                    { ADDRESS_NAME } — {ADDRESS_1}, { ADDRESS_2 } — <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
                </Col>
                <Col md={0} xs={24}>
                    { ADDRESS_NAME }<br/>
                    { ADDRESS_1 }<br/>
                    { ADDRESS_2 }<br/>
                    <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
                </Col>
            </Row>
        </div>
        <div style={{fontSize: '80%', marginTop: '24px'}}>
            <div>{ ADDRESS_NAME } is located in or around SHERMAN OAKS, VAN NUYS, STUDIO CITY, VALLEY GLEN, VALLEY VILLAGE, ENCINO, LAKE BALBOA, BEVERLY GLEN, BEL AIR</div>
            <div>Thank You For Looking, We Look Forward to Helping You!</div>
        </div>
    </div>,
    sections: [
        {
            element: UI.Logo,
            props: {
                logo: <img style={{height: '50px'}} src="./assets/logo.jpg"/>,
                name: ADDRESS_NAME,
                address: <div style={{lineHeight: 1}}>
                    { ADDRESS_1 }, { ADDRESS_2 } <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
                </div>
            }
        },
        {
            isNav: true,
            element: UI.Nav,
            props: {
                logo: {
                    logo: <img style={{height: '50px'}} src="./assets/logo.jpg"/>,
                    address: <div style={{lineHeight: 1}}>
                        <div style={{fontSize: '110%'}}>{ ADDRESS_NAME }</div>
                        <div style={{fontSize: '80%'}}>{ ADDRESS_1 }</div>
                        <div style={{fontSize: '80%'}}>{ ADDRESS_2 }</div>
                        <div style={{fontSize: '80%'}}>{PHONE_NUMBER}</div>
                    </div>
                },
                rightSide: (
                    <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL}/>
                ),
                rightSideModal: (
                    <div>
                        <Paragraph>
                            <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL}/>
                        </Paragraph>
                    </div>
                )
            }
        },
        {
            element: UI.HeroImage,
            props: {
                image: './assets/hero.jpg'
            }
        },
        {
            element: UI.Tagline,
            props: {
                tagline: 'Beautiful Villa Townhouses in the heart of Studio City',
                undertag: <div>
                    <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL}/>
                </div>
            }
        },
        {
            element: UI.AboveFold,
            props: {
                images: [
                    './assets/web/1-photo.jpg',
                    './assets/web/2-photo.jpg',
                    './assets/web/3-photo.jpg',
                    './assets/web/4-photo.jpg',
                    './assets/web/5-photo.jpg'
                ]
            }
        },
        {
            element: UI.SectionTextCarousel,
            label: UI.NAV_ITEM.UNITS,
            nav: 'Units',
            props: {
                title: 'About Our Apartments',
                sections: [features]
            }
        },
        {
            element: UI.SectionTextCarousel,
            label: UI.NAV_ITEM.AMENITIES,
            nav: 'Amenities',
            props: {
                title: 'Community Amenities',
                sections: [amentities]
            }
        },
        {
            element: UI.SectionImages,
            label: UI.NAV_ITEM.FLOORPLANS,
            nav: 'Floorplans',
            props: {
                title: 'Floorplans',
                images: [
                    {
                        color: '#b0984d',
                        name: '2+2 Model A',
                        info: <div>
                            <div><Text>2 Bedroom + 2 Bathroom</Text></div>
                            <div><Text strong style={{fontSize: 18}}>~1,150 SF</Text></div>
                            <div><Text type="secondary"><small>Additional 120 SF of balcony space depending on unit.</small></Text></div>
                        </div>,
                        image: "./assets/floorplans/Unit-12.jpg",
                        bottom: <div>Square footages are appoximate. Size and layout varies from unit to unit.</div>
                    }
                ]
            }
        },
        {
            element: UI.SectionTitle,
            label: UI.NAV_ITEM.CONTACT,
            nav: 'Tour',
            props: {
                title: 'Tour'
            }
        },
        {
            element: UI.SectionSideBySide,
            props: {
                leftSide: <div>
                    <h3>Schedule a tour with our on-site manager:</h3>
                    <div style={{marginTop: 24}}>
                        <h1 style={{}}><a href={`tel:${PHONE_NUMBER}`}>{ PHONE_NUMBER }</a></h1>
                        <div style={{fontSize: '80%'}}>Call anytime!</div>
                    </div>
                    <div style={{marginTop: 24}}>
                        <h1 style={{}}><a href={`mailto:${EMAIL}`}>{ EMAIL }</a></h1>
                        <div style={{fontSize: '80%'}}>Email us what you are looking for and some times that work for you.</div>
                    </div>
                    <div style={{marginTop: 24}}>
                        <Paragraph>
                            <UI.BookTourButton phone_number={PHONE_NUMBER} email={EMAIL}/>
                        </Paragraph>
                    </div>
                </div>,
                rightSide: (
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3301.9576111276606!2d-118.42237818478245!3d34.1474265805801!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c29620911683e3%3A0xa1dfd0fab944ab0c!2s4243%20Mary%20Ellen%20Ave%2C%20Studio%20City%2C%20CA%2091604!5e0!3m2!1sen!2sus!4v1598465845440!5m2!1sen!2sus" width="400" height="400" frameBorder={0} style={{border:"1px solid #CCC"}}></iframe>
                )
            }
        }
    ]
}